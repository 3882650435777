* {
    margin: 0;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../images/pc.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}

.loader-container > div {
    height: 20px;
    width: 20px;
    background-color: white;
    margin: 5px;
    border-radius: 50%;
    animation: loader 0.6s linear infinite;
}

.loader-container .loader-1 {
    animation-delay: 0.1s;
}

.loader-container .loader-2 {
    animation-delay: 0.2s;
}

.loader-container .loader-3 {
    animation-delay: 0.3s;
}

@keyframes loader {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0px);
    }
}
