* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden !important;
    font-family: "Poppins", sans-serif;
}

.home-container {
    width: 100%;
    min-height: 100vh;
    background-image: url("./images/pc.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}

.main-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

.card {
    width: 600px;
    height: 550px;
    padding: 2em;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.272);
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    border: 0.4px solid rgba(255, 255, 255, 0.32);
}

h1 {
    font-size: 2rem;
    font-weight: 900;
    color: #fff;
    margin-top: 10px;
}

h2 {
    color: white;
    font-size: 25px;
    margin-top: 10px;
    font-family: sans-serif;
    overflow: scroll;
}

.icon {
    margin-left: 5px;
    width: 75px;
    height: 70px;
    position: absolute;
    top: 20px;
}
/* Making Responsive */
@media (min-width: 540px) and (max-width: 960px) {
    .home-container {
        width: 100%;
        min-height: 100vh;
        background-image: url("./images/pc.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
    }

    .main-container {
        display: flex;
        max-width: 90%;
        margin: auto;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
    }

    .card {
        width: 600px;
        height: 550px;
        padding: 2em;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.272);
        backdrop-filter: blur(5px) saturate(180%);
        -webkit-backdrop-filter: blur(5px) saturate(180%);
        border: 0.4px solid rgba(255, 255, 255, 0.32);
    }

    h1 {
        font-size: 2rem;
        font-weight: 600;
        color: #fff;
        margin-top: 10px;
    }

    h6 {
        color: white;
        font-size: 1rem;
        margin-top: 10px;
    }

    .icon {
        margin-left: 5px;
        width: 75px;
        height: 70px;
        position: absolute;
        top: 20px;
    }
}

/* for  small devises */

@media (min-width: 425px) and (max-width: 540px) {
    .main-container {
        display: flex;
        max-width: 90%;
        margin: auto;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
    }

    .card {
        width: 600px;
        height: 550px;
        padding: 2em;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.272);
        backdrop-filter: blur(5px) saturate(180%);
        -webkit-backdrop-filter: blur(5px) saturate(180%);
        border: 0.4px solid rgba(255, 255, 255, 0.32);
    }

    h1 {
        font-size: 1.5em;
        font-weight: 600;
        color: #fff;
        margin-top: 10px;
        /* position: relative; */
    }

    h2 {
        color: white;
        font-size: 25px;
        margin-top: 10px;
    }

    .icon {
        margin-left: 5px;
        width: 55px;
        height: 50px;
        position: absolute;
        top: 30px;
    }
}

/* for ultra small devices */
/* for  small devises */

@media (min-width: 350px) and (max-width: 425px) {
    .main-container {
        display: flex;
        width: 90%;
        margin: auto;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
    }

    .card {
        width: 600px;
        height: 630px;
        padding: 2em;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.272);
        backdrop-filter: blur(5px) saturate(180%);
        -webkit-backdrop-filter: blur(5px) saturate(180%);
        border: 0.4px solid rgba(255, 255, 255, 0.32);
    }

    h1 {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        margin-top: 10px;
        /* position: relative; */
    }

    h2 {
        color: white;
        font-size: 25px;
        margin-top: 2px;
    }

    .icon {
        margin-left: 5px;
        width: 75px;
        height: 70px;
        position: relative;
        top: 5px;
    }
}

/* rest of all small devices */

@media (min-width: 0px) and (max-width: 350px) {
    .main-container {
        display: flex;
        width: 95%;
        margin: auto;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
    }

    .home-container {
        width: 100%;
        min-height: 100vh;
        background-image: url("./images/pc.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
    }

    .card {
        width: 600px;
        height: 630px;
        padding: 2em;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.272);
        backdrop-filter: blur(5px) saturate(180%);
        -webkit-backdrop-filter: blur(5px) saturate(180%);
        border: 0.4px solid rgba(255, 255, 255, 0.32);
    }

    h1 {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        margin-top: 10px;
    }

    h2 {
        color: white;
        font-size: 20px;
        margin-top: 2px;
    }

    .icon {
        margin-left: 5px;
        width: 75px;
        height: 70px;
        position: relative;
        top: 10px;
    }
}
