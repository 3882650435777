.myStyle {
    text-decoration: none;
}

.mySpace {
    position: relative;
    right: 5px;
    left: 1px;
    /* width: 50px; */
}

.myForm {
    position: relative;
    /* right: 95px; */
}
